import React from 'react'
import { createClient, Provider } from 'urql'

import Widget from 'components/widget'

const client = createClient({
  url: process.env.REACT_APP_API_URL ?? ''
})

const App = () => (
  <Provider value={client}>
    <Widget />
  </Provider>
)

export default App
