import React, { useState, useEffect } from 'react'
import { useQuery } from 'urql'

import styled from 'styled-components'

import { lighten, darken } from 'polished'

import { Pane, Button, Paragraph, CornerDialog, Heading, Position, Card } from 'evergreen-ui'
import { ReactComponent as MedalIcon } from 'assets/medal.svg'

const sessionTimeout = 0 * 60 * 1000 // milliseconds

const setIframeHeight = (height: number) => window.parent.postMessage({ type: 'RESIZE', y: height }, '*')

window.addEventListener('message', (m) => {
  if (m.data.type === 'INIT_IFRAME') {
    console.log('init outer!')
  }
})

type WidgetType = {
  id: string
  themeColor: string
  isLeft: boolean
  show: boolean
  showMobile: boolean
  title: string
  icon: WidgetIcon
  description: string
  buttonLabel: string
  solidButton: boolean
}

enum WidgetIcon {
  NONE = 'NONE',
  MEDAL = 'MEDAL'
}

const Widget = () => {
  useEffect(() => console.log('Pearly Embed Loaded'), [])

  const [isMobile, setIsMobile] = useState(false)
  const [position, setPosition] = useState(Position.BOTTOM_LEFT)

  const [code, setCode] = useState('')

  useEffect(() => {
    window.addEventListener('message', (m) => {
      if (m.data.type === 'IFRAME_PARAMS') {
        if (m.data.practiceCode) setCode(m.data.practiceCode)
        if (m.data.isMobile) setIsMobile(true)
      }
    })

    window.parent.postMessage({ type: 'INIT_WIDGET' }, '*')

    try {
      // Will throw error if window.parent is cross origin
      setCode(new URLSearchParams(window.parent.location.search).get('practice-code') ?? '')
    } catch (e) {}
  }, [])

  const [isShown, setIsShown] = useState(false)

  const timestamp = window.localStorage.getItem(`showPearlyWidget=${code}`)
  const now = new Date().getTime()
  const hasClosed = Boolean(timestamp && now - parseInt(timestamp) < sessionTimeout)

  const [{ data }] = useQuery<{ widget: WidgetType }>({
    query: `
    query ($code: String!) {
      widget(code: $code) {
        id
        themeColor
        isLeft
        show
        showMobile
        title
        icon
        description
        buttonLabel
        solidButton
      }
    }
  `,
    variables: { code },
    pause: !code || hasClosed
  })

  const widget = data?.widget

  const showWidget = () => {
    setIframeHeight(500)
    setTimeout(() => setIsShown(true))
  }

  useEffect(() => {
    if (data) {
      const widget = data.widget

      if (!isMobile && widget.show) {
        window.parent.postMessage({ type: 'REPOSITION', position: widget.isLeft ? 'left' : 'right' }, '*')
        setPosition(widget.isLeft ? Position.BOTTOM_LEFT : Position.BOTTOM_RIGHT)
        showWidget()
      }

      if (isMobile && widget.showMobile) {
        showWidget()
      }
    }
  }, [data, isMobile])

  const changeLocation = () => {
    console.log('Changing location', code)
    window.parent.postMessage({ type: 'CHANGE_LOCATION', location: `${process.env.REACT_APP_MEMBER_URL}/${code}` }, '*')
  }

  return !widget ? (
    <div />
  ) : (
    <CornerDialog
      onOpenComplete={() => {
        const dialogHeight = document.querySelector('[role="dialog"]')?.clientHeight ?? 0
        if (isMobile) {
          setIframeHeight(dialogHeight + 24)
        } else {
          setIframeHeight(dialogHeight + 58)
        }
      }}
      // @ts-ignore
      position={position}
      title={
        <Pane display="flex" alignItems="center">
          {widget.icon === WidgetIcon.MEDAL && (
            <Pane marginRight={8}>
              <MedalIcon height="40px" fill={widget.themeColor} />
            </Pane>
          )}

          <Heading size={600}>{widget.title}</Heading>
        </Pane>
      }
      hasFooter={false}
      width={isMobile ? 'calc(100% - 36px)' : 330}
      isShown={isShown}
      onCloseComplete={() => {
        setIsShown(false)
        window.parent.postMessage({ type: 'RESIZE', y: 0 }, '*')

        window.localStorage.setItem(`showPearlyWidget=${code}`, new Date().getTime().toString())
      }}
    >
      {!isMobile && (
        <Paragraph size={500} marginBottom={16} wordWrap="break-word">
          {widget.description}
        </Paragraph>
      )}
      {widget.solidButton ? (
        <StyledButton
          height={48}
          marginTop={8}
          appearance="primary"
          width="100%"
          justifyContent="center"
          backgroundColor={widget.themeColor}
          onClick={changeLocation}
        >
          {widget.buttonLabel}&nbsp;&nbsp; →<Card></Card>
        </StyledButton>
      ) : (
        <Button height={48} marginTop={8} width="100%" justifyContent="center" onClick={changeLocation}>
          {widget.buttonLabel}&nbsp;&nbsp; →
        </Button>
      )}
      {isMobile && (
        <style>
          {`
          div[role='dialog'] {
            padding: 18px;

            right: 18px;
            bottom: 18px;
            left: 18px;
          }
        `}
        </style>
      )}
    </CornerDialog>
  )
}

export default Widget

const StyledButton = styled(Button)<{ backgroundColor: string }>`
  background-image: ${(p) =>
    `linear-gradient(to bottom, ${lighten(0.1, p.backgroundColor)}, ${darken(0.1, p.backgroundColor)})`};

  &:hover {
    background-image: ${(p) =>
      `linear-gradient(to bottom, ${lighten(0, p.backgroundColor)}, ${darken(0.2, p.backgroundColor)}) !important`};
  }

  &:active {
    background-image: ${(p) =>
      `linear-gradient(to bottom, ${darken(0.1, p.backgroundColor)}, ${darken(0.3, p.backgroundColor)}) !important`};
  }
`
